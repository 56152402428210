<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item
              :to="{
                name: 'PostList',
              }"
              >文章管理</b-breadcrumb-item
            >
            <b-breadcrumb-item active>文章列表</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
        <h4 class="col-12 col-xl-2 mb-2 mb-xl-0 font-weight-bold">文章列表</h4>
        <div
          class="
            col-12 col-xl-8
            d-flex
            flex-column flex-xl-row
            align-items-start align-items-xl-center
          "
        >
          <b-button
            v-if="
              checkPermission([consts.POST_LIST_CREATE]) && !!this.merchantId
            "
            class="flex-shrink-0 mb-2 mb-xl-0 mr-2"
            variant="primary"
            @click="handleCreate"
          >
            <i class="fa fa-plus"></i>新增文章
          </b-button>
          <b-form-select
            v-model="merchantId"
            :options="merchants"
            @change="fetchPosts"
            :disabled="merchants.length == 2"
          ></b-form-select>

          <b-input-group class="ml-0 ml-xl-4">
            <b-form-input placeholder="標題＆內容" v-model="keyword"
              v-on:keyup.enter="fetchPosts(currentPage)"></b-form-input>
            <b-input-group-append>
              <b-button @click="fetchPosts(currentPage)"><i class="fa fa-search"></i></b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>

      <b-table
        striped
        hover
        responsive
        :items="posts"
        :fields="fields"
        :busy="isLoading"
        @sort-changed="handleSort"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>

        <template #cell(actions)="data">
          <b-button
            v-if="checkPermission([consts.POST_LIST_MODIFY])"
            variant="inverse-warning"
            :to="{
              name: 'PostEdit',
              params: {
                postId: data.item.id,
              },
            }"
            >編輯</b-button
          >

          <b-button
            v-if="checkPermission([consts.POST_LIST_MODIFY])"
            class="ml-2"
            variant="danger"
            @click="handleDelete(data.item.id, data.item.title)"
            >刪除</b-button
          >
        </template>
      </b-table>

      <div class="d-flex justify-content-center" style="margin-top: 80px">
        <b-pagination
          class="separated"
          v-model="currentPage"
          :total-rows="total"
          :per-page="perPage"
          aria-controls="postList"
        ></b-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import { format } from "date-fns";
import postApi from "../../../apis/post";
import merchantApi from "../../../apis/merchant";
import * as consts from "@/consts";
import PermissionChecker from "@/utils/PermissionChecker";
import postStatus from "./postStatus";

const statusMap = Object.entries(postStatus).reduce((map, [text, value]) => {
  map[value] = text;
  return map;
}, {});

export default {
  data() {
    return {
      consts,
      isLoading: false,
      search: "",
      posts: [],
      merchantId: null,
      merchants: [{ value: null, text: "請選擇" }],
      fields: [
        {
          key: "title",
          label: "標題",
          formatter: (value) => {
            if (value) {
              return this.hiddenString(value, 20)
            }
            return ''
          },
        },
        {
          key: "start_at",
          label: "上架時間",
          sortable: true,
          formatter: (value) => {
            if (value) {
              return format(new Date(value), 'yyyy-MM-dd HH:mm');
            } else {
              return ''
            }
          },
        },
        {
          key: "end_at",
          label: "下架時間",
          sortable: true,
          formatter: (value) => {
            if (value) {
              return format(new Date(value), 'yyyy-MM-dd HH:mm');
            } else {
              return ''
            }
          },
        },
        {
          key: "created_at",
          label: "上稿時間",
          sortable: true,
          formatter: (value) => {
            return format(new Date(value), 'yyyy-MM-dd HH:mm');
          },
        },
        {
          key: "status",
          label: "狀態",
          sortable: true,
        },
        {
          key: "actions",
          label: "管理",
        },
      ],
      perPage: 15,
      currentPage: 1,
      total: 0,
      keyword: null,
      orderBy: 'desc',
      sortBy: 'created_at',
    };
  },
  computed: {
    orgId() {
      return this.$route.params.orgId;
    },
  },
  watch: {
    currentPage: function (value) {
      this.fetchPosts(value);
    }
  },
  mounted() {
    if (!this.checkPermission([consts.POST_LIST_VIEW])) {
      this.$swal
        .fire({
          type: "error",
          text: "你沒有權限訪問此頁面",
          confirmButtonColor: "#d33",
        })
        .then(() => {
          this.$router.push({ name: "OrganizationList" });
        });
    } else {
      this.fetchMerchants();
    }
  },
  methods: {
    async fetchPosts(page = 1) {
      try {
        this.isLoading = true;
        const params = {
          page: page || this.currentPage,
          order_by: this.orderBy,
          sort_by: this.sortBy,
        };
        if (this.keyword) params.keyword = this.keyword;

        const { data } = await postApi.getPosts(this.merchantId, params);
        this.total = data.meta.total;
        this.perPage = data.meta.per_page;
        this.posts = data.data.map((post) => {
          post.status = statusMap[post.status] || "";
          return post;
        });
      } catch (error) {
        console.log("");
      }
      this.isLoading = false;
    },

    async handleDelete(postId, title) {
      this.$swal
        .fire({
          type: "warning",
          title: "你確定要刪除嗎？",
          html: `
            <div class="d-block">
              <div class="my-3">
                <div>文章標題：${title}</div>
              </div>
              <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
            </div>`,
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            try {
              this.isLoading = true;
              await postApi.deletePost(postId);
              await this.fetchPosts();
            } catch (error) {
              console.log("");
            }
            this.isLoading = false;
          }
        });
    },
    handleCreate() {
      this.$router.push({
        name: "PostCreate",
        query: { merchantId: this.merchantId },
      });
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
    async fetchMerchants() {
      const { data } = await merchantApi.list();

      this.merchants = [
        { value: null, text: "請選擇" },
        ...data.data.map((merchant) => {
          return {
            value: merchant.id,
            text: `${merchant.name} (${merchant.type_name})`,
          };
        }),
      ];

      let defaultMerchant
      if (data.data.length == 1) {
        defaultMerchant = data.data[0]
      } else {
        defaultMerchant = data.data.filter(merchant => merchant.type == 'LINE').shift()
      }

      if (this.$route.query.merchant_id) {
        this.merchantId = this.$route.query.merchant_id

        var hasMerchant = this.merchants.find((item) => {
          return item.value === this.merchantId
        })

        if (!hasMerchant) {
          this.$swal
            .fire({
              type: "warning",
              title: "查無此通路",
              confirmButtonText: "確定",
              confirmButtonColor: "#d33",
              reverseButtons: true,
            })
          return
        }

        this.fetchPosts()
        return
      }

      if (defaultMerchant) {
        this.merchantId = defaultMerchant.id;
        this.fetchPosts()
      }
    },
    hiddenString(value, length) {
      value = value || ''
      const splittedValue = [...value]
      if (splittedValue.length <= length) {
        return value
      }
      return splittedValue.slice(0, length).join('') + '...'
    },
    handleSort(ctx) {
      this.sortBy = ctx.sortBy;
      this.orderBy = ctx.sortDesc ? "desc" : "asc";
      this.currentPage = 1
      this.fetchPosts();
    },
  },
};
</script>
